<script setup lang="ts">
const route = useRoute();
const to = route.query.to as string;
const params = { to: to };

defineOptions({
  name: "AccountLayout",
});

useAuthGuardRedirection(params);

// Navigation for Account page
const { loadNavigationElements } = useNavigation();
const { logout } = useUser();
const router = useRouter();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { data } = await useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 2 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = await useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 2 });
});

const { loadNavigationElements: loadTopNavigationElements } = useNavigation({
  type: "service-navigation",
});
const { data: topNavigationData } = useAsyncData( "mainServiceNavigation", ()=> {
  return loadTopNavigationElements({depth: 1})
})
provide("swNavigation-service-navigation", topNavigationData)

async function invokeLogout() {
  logout();
  router.push("/");
}

provide("swNavigation-footer-navigation", footerData);

const helloAgainPoints = ref(0);

const performHelloAgainPoints = async() => {
  const user = useUser();
  if(user && user.user && user.user.value && user.user?.value.email) {
    const res = await $fetch('https://api.helloagain.at/external/api/v1/users/?email='+user.user.value.email, {
      method: 'GET',
      headers: {
        "helloagain-api-key": "5572d731-518c-4028-90f8-49d90c116319"
      }
    });
    if(res && Array.isArray(res) && res.length > 0) {
      helloAgainPoints.value = res[0].points;
    }
  }
};

// Wait for component to mount and then get user data
onMounted(async () => {
  await performHelloAgainPoints();
});
</script>

<template>
  <div>
    <LayoutHeader />
    <LayoutNotifications />
    <LayoutBreadcrumbs />
    <div class="max-w-screen-xl mx-auto min-h-full">
      <div class="m-10">
        <div class="account-inner">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <aside class="block lg:w-64 md:col-span-" aria-label="Sidebar">
              <div
                class="overflow-y-auto md:py-4 md:px-3 md:bg-secondary-50 text-base font-normal text-secondary-500 rounded"
              >
                <ul class="space-y-2 list-none pl-0">
                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <NuxtLink
                      :to="formatLink(`/account`)"
                      exact-active-class="btn_secondary w-full max-w-full normal-case hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                    >
                      <div i-carbon-dashboard text-xl inline-block />
                      <span class="ml-3">
                        {{ $t("account.menu.accountOverviewHeader") }}</span
                      >
                    </NuxtLink>
                  </li>
                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <NuxtLink
                      :to="formatLink(`/account/profile`)"
                      exact-active-class="btn_secondary w-full max-w-full normal-case hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                    >
                      <div i-carbon-user text-xl inline-block />
                      <span class="ml-3">{{ $t("account.yourProfile") }}</span>
                    </NuxtLink>
                  </li>
                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <NuxtLink
                      :to="formatLink(`/account/address`)"
                      exact-active-class="btn_secondary w-full max-w-full normal-case hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                    >
                      <div i-carbon-home text-xl inline-block />
                      <span class="ml-3">{{ $t("account.yourAddress") }}</span>
                    </NuxtLink>
                  </li>
                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <NuxtLink
                      :to="formatLink(`/account/payment`)"
                      exact-active-class="btn_secondary w-full max-w-full normal-case hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                    >
                      <div i-carbon-wallet text-xl inline-block />
                      <span class="ml-3">{{
                        $t("account.myPaymentsHeader")
                      }}</span>
                    </NuxtLink>
                  </li>

                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <NuxtLink
                      :to="formatLink(`/account/order`)"
                      exact-active-class="btn_secondary w-full max-w-full normal-case hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                    >
                      <div i-carbon-order-details text-xl inline-block />
                      <span class="ml-3">{{
                        $t("account.orderHistoryHeader")
                      }}</span>
                    </NuxtLink>
                  </li>
                  <li class="border-b-4 border-solid border-cream py-1 sm:py-3 text-center">
                    <button
                      class="w-full max-w-full leading-8 hover:bg-black03 hover:text-white rounded-[24px] h-11 sm:h-12 flex items-center justify-center"
                      @click="invokeLogout()"
                    >
                      <div i-carbon-logout text-xl inline-block />
                      <span class="ml-3 text-secondary-700">{{
                        $t("account.logout")
                      }}</span>
                    </button>
                  </li>
                  <li>
                    <div class="bg-pink py-1 sm:py-3 px-2 lg:px-5 sm:mt-10 text-center text-white">
                      <p>{{$t("points.you_have")}}</p>
                      <h4 class="text-xl lg:text-2xl"><strong>{{helloAgainPoints}}</strong> {{$t('bonus.points')}}</h4>
                    </div>

                  </li>
                </ul>
              </div>
            </aside>
            <main class="md:col-span-2">
              <slot />
            </main>
          </div>
        </div>
      </div>
    </div>

    <LayoutFooter />
  </div>
</template>
